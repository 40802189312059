import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { FaSpinner } from "react-icons/fa6";
import "./App.css";
import React, { Suspense, lazy } from "react";

// import for Home
const HomePage = lazy(() => import("./pages/AstrolobbyPages/Home"));
const ErrorPage = lazy(() => import("./pages/AstrolobbyPages/Error"));
const Documentation = lazy(() => import("./pages/Documentation/Documentation"));
const RootLayout = lazy(() => import("./pages/AstrolobbyPages/Root"));
const ZodiacDetails = lazy(() =>import("./pages/AstrolobbyPages/ZodiacDetails"));
const ZodiacList = lazy(() => import("./pages/AstrolobbyPages/ZodiacList"));
const Fortunes = lazy(() => import("./pages/AstrolobbyPages/Fortunes"));
const IliskiUyum = lazy(() =>import("./pages/AstrolobbyPages/Hesaplamalar/IsımUyumu"));
const YukselenBurc = lazy(() =>import("./pages/AstrolobbyPages/Hesaplamalar/Yukselen"));
const TarotTekKart = lazy(() =>import("./pages/AstrolobbyPages/Hesaplamalar/TekKart"));
const SansKurabiyesi = lazy(() =>import("./pages/AstrolobbyPages/Hesaplamalar/SansKurabiyesi"));
const AskFali = lazy(() =>import("./pages/AstrolobbyPages/Hesaplamalar/AskFali"));
const KariyerFali = lazy(() =>import("./pages/AstrolobbyPages/Hesaplamalar/KariyerFali"));
const PolicyPage = lazy(() => import("./pages/AstrolobbyPages/Policy"));
const CookiePolicy = lazy(() => import("./pages/AstrolobbyPages/CookiePolicy"));
const UsePolicy = lazy(() => import("./pages/AstrolobbyPages/UsePolicy"));

// import for Sozluk
// const RootLayoutSozluk = lazy(() => import("./pages/SozlukPages/Root"));
// const HomeSozluk = lazy(() => import("./pages/SozlukPages/Home"));
const CommingSoon = lazy(() => import("./pages/SozlukPages/CommingSoon"));


const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "*", element: <ErrorPage /> },
      { path: "/doc", element: <Documentation /> },
      { path: "/burc", element: <ZodiacList /> },
      { path: "/burc/:isim", element: <ZodiacDetails /> },
      { path: "/hesaplamalar", element: <Fortunes /> },
      { path: "/hesaplamalar/isimUyumu", element: <IliskiUyum /> },
      { path: "/hesaplamalar/yukselen", element: <YukselenBurc /> },
      { path: "/hesaplamalar/tekkart", element: <TarotTekKart /> },
      { path: "/hesaplamalar/sanskurabiyesi", element: <SansKurabiyesi /> },
      { path: "/hesaplamalar/askfali", element: <AskFali /> },
      { path: "/hesaplamalar/kariyerfali", element: <KariyerFali /> },
      { path: "/gizlilik-sozlesmesi", element: <PolicyPage /> },
      { path: "/cerez-politikasi", element: <CookiePolicy /> },
      { path: "/kullanim-kosullari", element: <UsePolicy /> },
      { path: "/mistikSozluk", element: <CommingSoon /> },
    ],
  },
  // {path:'/mistikSozluk',
  //   element: <RootLayoutSozluk />,
  //   errorElement: <ErrorPage />,
  //   children: [
  //     { index: true, element: <CommingSoon /> },
  //     { path: "*", element: <ErrorPage /> },
  //   ]
  // }
]);

function App() {
  return (
    <Suspense
      fallback={
        <div className="skeleton-loading  text-center relative ">
          
          <p className="h-[100vh] flex flex-col justify-center items-center">
            <span className="animate-spin">
              <FaSpinner className="text-astro-white" size={86} />
            </span>
          </p>
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
